<template>
<div style="display: flex;flex: 1;">
    <div class="user-block-avatar">
        <img v-if="comment.user" :src="comment.user.avatar">
        <img v-else :src="'https://static.tgju.org/views/default/images/tgju-user-avatar.png'">
    </div>
    <div class="user-block-data">
        <router-link v-if="comment.user" :to="{ name: 'ProfileOthers', params: {  username : comment.user.username } }" class="title pt-0" style="display: inline-block;">
            <span class="title">{{ comment.user.firstname + ' ' + comment.user.lastname }}</span>
        </router-link>
        <a v-else src="#" target="_blank" class="title pt-0" style="display: inline-block;">
            {{ comment.name }}
            <span class="user-block-title-tag">@{{ comment.name }}</span>
        </a>
        <div class="user-block-comment-item-content">
            <div v-html="comment.content"></div>
            <div class="user-block-comment-items">
                <ul>
                    <li class="like">
                        <div class="post-btn-item pr-0">
                            <i class="fa fa-heart post-btn-icon pointer" aria-hidden="true" v-if="comment.liked" @click="likeComment(comment.id)"></i>
                            <i class="fa fa-heart-o post-btn-icon pointer" aria-hidden="true" v-else @click="likeComment(comment.id)"></i>
                            <span class="post-btn-count">{{ comment.likes }}</span>
                        </div>
                    </li>
                    <li class="reply pointer" @click="goReplyComment(comment.id)">پاسخ</li>
                    <li class="time-reply"><i class="fa fa-clock-o" aria-hidden="true"></i> {{ toRelative(comment.created_at) }}</li>
                </ul>
            </div>
        </div>
        <ul class="user-block-comment-reply">
            <li class="user-block-comment-item" v-for="childComment in comment.comments" :key="childComment.id">
                <CommentItem :comments="comment.comments" :comment="childComment" :group_id="group_id"></CommentItem>
            </li>
        </ul>
    </div>
</div>
</template>

<style lang="scss" scoped>
.user-block-comment-item {
    display: block;
}
</style>

<script>
import moment from "moment-jalaali";

export default {
    name: 'CommentItem',
    components: {},
    props: ['comments', 'comment', 'group_id'],
    data: function () {
        return {}
    },
    computed: {
        newPost: {
            get() {
                return this.$parent.newPost;
            },
            set(value) {
                this.$parent.newPost = value;
            }
        },
    },
    watch: {
        comment: {
            handler: function (newValue, oldValue) {
                if (Object.keys(newValue).length) {
                    this.showMenu = false;
                    this.showShareMenu = false;

                    if (this.comment.user) {
                        if (this.$helpers.isJson(this.comment.user.avatar)) {
                            this.comment.user.avatar = this.$helpers.api_url() + this.$helpers.json_pars(this.comment.user.avatar).name
                        } else {
                            this.comment.user.avatar = 'https://static.tgju.org/views/default/images/tgju-user-avatar.png';
                        }
                    }

                    this.comment.content = this.comment.content.replaceAll(/<br><img class="post-img".*?>/gmi, '');
                    if (this.comment.attachments.length) {
                        this.comment.content += `<br><img class="post-img" src="${this.comment.attachments[0].name}">`;
                    }
                }
            },
            immediate: true
        }
    },
    mounted() {},
    methods: {
        toRelative(date) {
            return moment(date).fromNow();
        },
        likeComment(id) {
            let index = this.$helpers.getIndex(this.comments, id);
            let comment = this.comments[index];

            let api_data = {
                action: comment.liked ? 'unlike' : 'like'
            };

            this.$helpers.makeRequest('GET', '/post/like/' + id, api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                if (api_response.status == 200) {
                    comment.liked = !comment.liked;
                    comment.likes = api_response.data.response.likes;
                }
            });
        },
        goReplyComment(id) {
            let index = this.$helpers.getIndex(this.comments, id);
            let comment = this.comments[index];

            this.newPost.parent_id = comment.id;
            this.newPost.parent = comment;
            this.newPost.content = '@' + this.newPost.parent.user.username;

            let parent = this.$helpers.getParent(this);

            parent.setCaretToEnd();
            parent.scrollTo('#global-dashboard-modal[data-component="PostComments"]');
        },
    },
}
</script>
